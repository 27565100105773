import React, { useCallback, useState } from 'react';
import { useAuthModalContext } from 'context/AuthContext';
import { Login } from 'pages/auth/Login';
import { Register } from 'pages/auth/Register';
import { ForGotPassword } from 'components/common/ForGotPassword/ForGotPassword';
import { Verification } from 'pages/auth/Verification';
import { useMutation } from '@apollo/client';
import { NEW_STUDENT_REGISTER } from 'pages/auth/core/_requests';
import { RegistrationUserOutput } from 'generated/types';

const LoginAndRegisterModals: React.FC = () => {
	const { open, openRegister, forgotPassword, verification } = useAuthModalContext();

	const [userRegisterDetails, setUserRegisterDetails] = useState({
		email: '',
		password: '',
		mobileNo: '',
		name: '',
	});

	const [studentRegister] = useMutation(NEW_STUDENT_REGISTER);

	const registerStudentMutation = useCallback(
		async ({
			email,
			password,
			mobileNumber,
			fullName,
			onCompleted,
			onError,
		}: {
			email: string;
			password: string;
			mobileNumber: string;
			fullName: string;
			onCompleted?: (data: { newStudentRegister: RegistrationUserOutput }) => void;
			onError?: () => void;
		}) => {
			const { data } = await studentRegister({
				variables: {
					email,
					password,
					mobileNo: mobileNumber,
					name: fullName,
				},
				onCompleted: (data: { newStudentRegister: RegistrationUserOutput }) => {
					if (onCompleted) {
						onCompleted(data);
					}
					setUserRegisterDetails({
						email,
						password,
						mobileNo: mobileNumber,
						name: fullName,
					});
				},
				onError: () => {
					if (onError) {
						onError();
					}
				},
			});
			return data?.newStudentRegister;
		},
		[],
	);

	return (
		<>
			{open && <Login />}
			{openRegister && <Register registerStudentMutation={registerStudentMutation} />}
			{forgotPassword && <ForGotPassword />}
			{verification && (
				<Verification userDetails={userRegisterDetails} handleResendOTP={registerStudentMutation} />
			)}
		</>
	);
};

export default LoginAndRegisterModals;
