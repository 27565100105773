import React, { useEffect, useMemo, useState } from 'react';
import './PaymentDetails.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { BASE_PAGE_URL } from 'pages/routing/routePages';
import { useNavigate, useParams } from 'react-router-dom';
import { useCourseContext } from 'context/CourseContext';
import { Course, Mentor } from '../Course/type';
import { RUPEE_SYMBOL } from 'utils/consts';
import useCourses from 'hooks/useCourses';
import { useMutation } from '@apollo/client';
import { CREATE_ORDER_MUTATION } from 'components/Cart/core/requests';
import toast from 'react-hot-toast';
import { useUserContext } from 'context/UserContext';
import { CreateOrderDetailsInput } from 'types/globalTypes';
import Loading from 'components/_v2/CommonComponents/Loading';
import Button from '../CommonComponents/Button';
import { ButtonBorderType, ButtonType } from '../CommonComponents/types';

const PaymentBorder = <div className="payment-border"></div>;

interface MoneyDistributionProps {
	heading: string;
	amount: number;
	classes: string;
}

const MoneyDistribution: React.FC<MoneyDistributionProps> = ({ heading, amount, classes }) => (
	<div className={`d-flex justify-content-between g_reg ${classes}`}>
		<div>{heading}:</div>
		<div>
			{RUPEE_SYMBOL}
			{amount.toFixed(2)}
		</div>
	</div>
);

const PaymentDetails = () => {
	const navigate = useNavigate();
	const { user } = useUserContext();
	const { courseSlug } = useParams();
	const { hasValueSet } = useCourseContext();
	const { getCourses, courses } = useCourses();
	const [createOrder, { data, loading }] = useMutation(CREATE_ORDER_MUTATION);
	const [loadingNewUrl, setLoadingNewUrl] = useState(false);

	useEffect(() => {
		if (!hasValueSet) {
			getCourses();
		}
	}, [hasValueSet]);

	const selectedCourse = useMemo(() => {
		if (courses.length) {
			const course = courses.find((course: Course) => course.slug === courseSlug);
			if (!course) {
				navigate(BASE_PAGE_URL);
			}
			return course;
		}
	}, [courses]);

	const totalDiscount = useMemo(
		() => (selectedCourse?.amount ?? 0) - (selectedCourse?.sellPrice ?? 0),
		[selectedCourse],
	);

	const gstOverSellPrice = useMemo(() => {
		const gst = ((selectedCourse?.sellPrice ?? 0) / 100) * 18;
		return parseFloat(gst.toFixed(2));
	}, [selectedCourse]);

	const totalPayable = useMemo(
		() => gstOverSellPrice + (selectedCourse?.sellPrice ?? 0),
		[selectedCourse],
	);

	const handleSuccessPageReroute = () => {
		const cartItems: CreateOrderDetailsInput[] = [
			{
				courseId: selectedCourse.id,
				noOfSeat: 1,
				price: selectedCourse?.amount,
				totalPrice: totalPayable,
				subTotal: selectedCourse?.sellPrice ?? 0,
				tax: 18,
				taxAmount: gstOverSellPrice,
			},
		];
		createOrder({
			variables: {
				createOrderInputData: {
					firstName: user.firstName,
					lastName: user.lastName,
					city: user.userAddress?.[0]?.city ?? '',
					state: user.userAddress?.[0]?.state ?? '',
					zipCode: user.userAddress?.[0]?.zipCode ?? '395001',
					address: user.userAddress?.[0]?.address ?? '',
					totalDiscount,
					companyName: '',
					registerAddress: '',
					cartitems: cartItems,
					gstNo: '',
				},
			},
			onCompleted: (data: any) => {
				toast.success('Successfully created Order', {
					style: {
						padding: '16px',
					},
				});
				setLoadingNewUrl(true);
				window.location.href = data.createOrder.short_url;
			},
			onError: (err: any) => {
				toast.error(err.message);
			},
		});
	};

	return (
		<section className="payment-detail-wrapper">
			<div className="container">
				<div className="row justify-content-center">
					<div className="card-layout">
						<div className="card px-2">
							<div className="d-flex justify-content-between align-items-center p-3">
								<div>
									<img
										src={toAbsoluteUrl('/media/logo/leftarrow.png')}
										alt="Left"
										onClick={() => navigate(-1)}
										className=""
									/>
								</div>
								<div>
									<img
										src={toAbsoluteUrl('/media/logo/hoGrowthBlack.svg')}
										alt="Logo"
										className="logo-layout"
									/>
								</div>
								<div>
									<img
										src={toAbsoluteUrl('/media/logo/leftarrow.png')}
										alt="Left"
										onClick={() => navigate(-1)}
										className="opacity-0"
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="d-flex align-items-center">
									<div>
										<img
											src={toAbsoluteUrl('/media/payment-details/payment-img.png')}
											alt="Course_Image"
											className="img-fluid"
										/>
									</div>
									<div className="ms-3">
										<div className="payment-course-name g_mid">{selectedCourse?.name}</div>
										<div className="payment-course-label g_reg">
											by{' '}
											{(selectedCourse?.mentors || []).map(
												(mentor: { mentor: Mentor }, index: number) => {
													const mentorName = mentor.mentor.firstName + ' ' + mentor.mentor.lastName;
													return (
														mentorName +
														((selectedCourse?.mentors?.length || 1) - 1 != index ? ', ' : '')
													);
												},
											)}
										</div>
									</div>
								</div>
								<div>
									<p className="summary-heading g_reg">Order Summary:</p>
								</div>
								<div className="pt-1">
									<span className="payment-discount-price g_mid me-3">
										{RUPEE_SYMBOL}
										{selectedCourse?.sellPrice}
									</span>
									<span className="payment-actual-price g_reg ">
										<del>
											{RUPEE_SYMBOL}
											{selectedCourse?.amount}
										</del>
									</span>
								</div>
								{PaymentBorder}
								{MoneyDistribution({
									amount: selectedCourse?.amount ?? 0,
									classes: 'amount-label',
									heading: 'Course Amount',
								})}

								{MoneyDistribution({
									amount: totalDiscount,
									classes: 'amount-label',
									heading: 'Discount',
								})}

								{MoneyDistribution({
									amount: selectedCourse?.sellPrice ?? 0,
									classes: 'subtotal-label',
									heading: 'Subtotal',
								})}

								{MoneyDistribution({
									amount: gstOverSellPrice,
									classes: 'amount-label',
									heading: 'GST @ 18%',
								})}
								{PaymentBorder}
								{MoneyDistribution({
									amount: totalPayable,
									classes: 'total-price-label',
									heading: 'Total Payable',
								})}

								<div>
									<Button
										buttonText={
											<span className="d-flex justify-content-center gap-2">
												{(loading || loadingNewUrl) && <Loading width={50} />}
												<span>
													Proceed to Pay{'  '}
													<span>
														{RUPEE_SYMBOL}
														{totalPayable.toFixed(2)}
													</span>
												</span>
											</span>
										}
										buttonType={ButtonType.PRIMARY_BUTTON}
										classes={`g_mid w-100 ${loading ? 'opacity-50' : ''}`}
										handleClick={handleSuccessPageReroute}
										buttonBorderType={ButtonBorderType.BLACK_BORDER}
										disabled={loading || loadingNewUrl}
									/>
								</div>
								<div className="text-center">
									<span className="payment-sub-desc g_reg">
										By proceeding you agree to our Terms, Privacy & Refund Policy
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PaymentDetails;
