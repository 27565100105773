import React, { useCallback } from 'react';
import { LessonType, LessonTypeToText } from 'components/_v2/Course/type';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import {
	QuizReattemptDetails,
	QuizResultStatusEnum,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import { CERTIFICATE_LESSON } from 'types/globalTypes';

const quizDurationFormatter = (duration: number) => {
	const minutes = Math.floor(duration / 60);
	const remainingSeconds = duration % 60;
	return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const AccordionBody = ({
	lessons,
	showLockIcon,
	currentSelectedLessonId,
	quizReattemptList,
	handleLessonChange,
	fullScale,
	hasCertificate = false,
}: {
	lessons: LessonType[];
	showLockIcon: boolean;
	currentSelectedLessonId?: string;
	quizReattemptList?: QuizReattemptDetails[];
	handleLessonChange?: (lessonId: string) => void;
	fullScale?: boolean;
	hasCertificate?: boolean;
}) => {
	const listItemRenderer = useCallback(
		(lesson: LessonType) => {
			const quizDuration = lesson.quiz && quizDurationFormatter(lesson.quiz.duration);
			const showYellowBackground = lesson.id === currentSelectedLessonId;
			const showPendingOrFailedQuizStatus = quizReattemptList?.find(
				(quizDetails) => quizDetails.lessonId === lesson.id && quizDetails.quizId === lesson.quizId,
			);
			const isCertificateDisabledAdditionalCondition =
				lesson.id === CERTIFICATE_LESSON && !!quizReattemptList?.length;

			const allowReattemptQuiz =
				quizReattemptList?.find(
					(listItem) => listItem.lessonId === lesson.id && listItem.quizId === lesson.quizId,
				)?.allowReAttempt || false;

			const renderQuizStatus = (quizReattemptDetails: QuizReattemptDetails) => {
				switch (quizReattemptDetails?.status) {
					case QuizResultStatusEnum.PENDING:
						return (
							<span
								style={{ color: '#D66600', fontSize: '12px' }}
								className="d-flex align-items-center p_reg gap-2">
								<Image
									src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')}
									height="14px"
								/>
								Please attempt the quiz to continue
							</span>
						);
					case QuizResultStatusEnum.FAILED:
						return (
							<span
								style={{
									color: quizReattemptDetails?.allowReAttempt ? '#d66600' : '#EE0000',
									fontSize: '12px',
								}}
								className="d-flex align-items-center gap-2">
								<Image
									src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')}
									height="14px"
								/>
								{quizReattemptDetails?.allowReAttempt
									? 'Please reattempt the quiz to continue'
									: 'You have failed all attempts on quiz'}
							</span>
						);
					default:
						return null;
				}
			};

			const addExtraFunctions = handleLessonChange
				? {
						onClick: () =>
							lesson?.disabledSelection || isCertificateDisabledAdditionalCondition
								? null
								: handleLessonChange(lesson.id),
				  }
				: {};

			return (
				<div
					className={`d-flex flex-md-row rounded-2 flex-row gap-2 p-2 ${
						showYellowBackground ? 'selectedLesson' : ''
					} ${
						lesson.disabledSelection || isCertificateDisabledAdditionalCondition
							? 'disabledSelection'
							: ''
					}`}
					{...addExtraFunctions}>
					<div className="align-item-center">
						{fullScale ? (
							<Image src={toAbsoluteUrl('/media/icons/list-item.svg')} height={20} width={20} />
						) : (
							<Image src={toAbsoluteUrl('/media/icons/circle-bullet.svg')} height={20} width={20} />
						)}
					</div>
					<div className="flex-grow-1 flex-column text-xl-start text-lg-start text-start">
						<p className="p_reg mb-1">{lesson.name}</p>
						{lesson.quizId &&
							[QuizResultStatusEnum.PENDING, QuizResultStatusEnum.FAILED].includes(
								showPendingOrFailedQuizStatus?.status,
							) &&
							renderQuizStatus(showPendingOrFailedQuizStatus)}
						{isCertificateDisabledAdditionalCondition && (
							<div className="d-flex align-items-center gap-2">
								<Image
									src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')}
									height="14px"
								/>
								<span className="fs-12 text-danger p_reg">
									Pass all quizzes{' '}
									{hasCertificate ? 'to enable download certificate' : 'for course completion'}
								</span>
							</div>
						)}
					</div>

					{lesson.quizId && fullScale ? <p>{quizDuration} min</p> : null}
					{showLockIcon ? (
						<>
							{showPendingOrFailedQuizStatus?.status === QuizResultStatusEnum.FAILED &&
							!allowReattemptQuiz ? (
								<Image src={toAbsoluteUrl('/media/course-detail-images/failed-quiz.svg')} />
							) : showPendingOrFailedQuizStatus?.status === QuizResultStatusEnum.FAILED &&
							  allowReattemptQuiz ? (
								<Image src={toAbsoluteUrl('/media/course-detail-images/reattempt-quiz.svg')} />
							) : (
								<Image
									src={toAbsoluteUrl(
										`/media/course-detail-images/square-${
											lesson.isLock || isCertificateDisabledAdditionalCondition
												? 'lock.svg'
												: 'unlock.svg'
										}`,
									)}
								/>
							)}
						</>
					) : (
						<button className="btn type-btn pe-none">{LessonTypeToText[lesson.type]}</button>
					)}
				</div>
			);
		},
		[currentSelectedLessonId, quizReattemptList, handleLessonChange],
	);

	return (
		<div className="accordion-body">
			{lessons.map((lesson) => (
				<div key={lesson.id} className="content-list">
					{listItemRenderer(lesson)}
				</div>
			))}
		</div>
	);
};

export default AccordionBody;
