import { getUserByToken } from 'pages/auth/core/_requests';
import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useMemo,
	useEffect,
	useCallback,
} from 'react';
import { UserType } from 'types/globalTypes';
import * as authHelper from 'pages/auth/core/AuthHelpers';
import { toast } from 'react-hot-toast';
import { VERIFY_USER_STORAGE_KEY } from 'pages/auth/core/AuthHelpers';

interface UserContextValue {
	user: UserType | null;
	setUser: React.Dispatch<React.SetStateAction<UserType | null>>;
	logOutUser: () => void;
	localStorageAuthToken: string;
	refetchLocalStorageTokenDetails: () => void;
}

const UserContext = createContext<UserContextValue | undefined>(undefined);

interface UserProviderProps {
	children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [user, setUser] = useState<UserType | null>(null);
	const [localStorageAuthToken, setLocalStorageAuthToken] = useState(
		authHelper.getAuth()?.access_token,
	);

	const logOutUser = useCallback(() => {
		setUser(null);
		authHelper.removeAuth();
	}, []);

	const refetchLocalStorageTokenDetails = () => {
		setLocalStorageAuthToken(authHelper.getAuth().access_token);
	};

	const fetchUser = async () => {
		try {
			const { data } = await getUserByToken(localStorageAuthToken);
			setUser(data?.data?.getMe);
			localStorage.removeItem(VERIFY_USER_STORAGE_KEY);
		} catch (error) {
			console.error('Error fetching user data:', error);
			toast.error('Failed fetching user details. Please try again.');
		}
	};

	useEffect(() => {
		if (localStorageAuthToken) {
			fetchUser();
		}
	}, [localStorageAuthToken]);

	const value = useMemo(() => {
		return {
			user,
			setUser,
			localStorageAuthToken: localStorageAuthToken ?? '',
			logOutUser,
			refetchLocalStorageTokenDetails,
		};
	}, [user, setUser, localStorageAuthToken, logOutUser]);

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextValue => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserContext must be used within a UserProvider');
	}
	return context;
};
