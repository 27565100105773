import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { EHeaderType, UserType } from 'types/globalTypes';
import './LoggedInUser.css';
import ProfilePopup from './ProfilePopup';
import NotificationPopup from './NotificationPopup';
import { StudentCoursesResponse } from '../Course/type';
import { GET_STUDENT_COURSES } from 'pages/auth/core/_requests';
import { useCourseContext } from 'context/CourseContext';
import {
	NotificationsDataType,
	NotificationSentStatusEnum,
	NotificationsObjectType,
	NotificationStatusEnum,
	NotificationTypeEnum,
	StudentNotificationsListDataType,
} from './types';
import { useNotificationContext } from 'context/NotificationContext';
import useNotification from 'hooks/useNotification';

const GET_ALL_NOTIFICATIONS = gql`
	subscription ($topicsForNotifications: String!) {
		getNotifications(topic: $topicsForNotifications) {
			id
			message
			title
			createdAt
		}
	}
`;

const STUDENT_NOTIFICATIONS_LIST = gql`
	query LoggedInUser_Query(
		$where: NotificationsResolver_GetStudentNotificationList_FilterInputType
		$paginate: PaginatorArgs!
	) {
		getStudentNotificationList(where: $where, paginate: $paginate) {
			records {
				id
				title
				message
				createdAt
				readStatus
			}
			pagination {
				totalRecords
				currentPage
			}
		}
	}
`;

const READ_ALL_NOTIFICATIONS = gql`
	mutation {
		readAllNotifications
	}
`;

interface LoggedInUserInterface {
	user: UserType;
	userProfileIcon: boolean;
	notification_icon: boolean;
	disableClick: boolean;
}

const LoggedInUser: React.FC<LoggedInUserInterface> = ({
	user,
	userProfileIcon,
	notification_icon,
	disableClick,
}) => {
	const [openUserProfile, setOpenUserProfile] = useState<boolean>(false);
	const [openNotifications, setOpenNotifications] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<NotificationsObjectType[]>([]);
	const { userCourses } = useCourseContext();
	const { studentNotifications, setStudentNotifications } = useNotificationContext();
	const { getStudentNotifications } = useNotification();
	const imageUrl = user?.userProfile?.[0]?.imageUrl
		? user.userProfile[0].imageUrl
		: toAbsoluteUrl('/media/header/static-user.svg');

	// console.log("🚀 ~ studentNotifications:", studentNotifications);
	useEffect(() => {
		getStudentNotifications().catch((error) => {
			console.error('Error fetching notifications:', error);
			return <p className="text-center">Error: {error.message}</p>;
		});
	}, []);
	const lsValue = localStorage.getItem('auth_token');
	const authToken = lsValue ? JSON.parse(lsValue).access_token : null;

	const [markAllNotificationsAsRead] = useMutation(READ_ALL_NOTIFICATIONS, {
		onError: (error) => {
			console.error('Error marking notifications as read:', error);
		},
	});

	const [topicsForNotifications, setTopicsForNotifications] = useState('GENERAL,GUEST_USER');

	useEffect(() => {
		if (userCourses) {
			const courseSlugs = userCourses?.map((record) => record?.slug);
			if (courseSlugs && courseSlugs.length > 0) {
				setTopicsForNotifications(['GENERAL', 'ENROLLED_USER', ...courseSlugs].join(','));
			}
		}
	}, [userCourses]);

	const { data: allNotifications } = useSubscription<NotificationsDataType>(GET_ALL_NOTIFICATIONS, {
		variables: { topicsForNotifications },
	});

	useEffect(() => {
		if (studentNotifications) {
			const formattedNotifications = studentNotifications
				.map((notification: NotificationsObjectType) => ({
					id: notification.id,
					title: notification.title,
					message: notification.message,
					createdAt: new Date(notification.createdAt),
					readStatus: notification.readStatus,
					type: notification.type,
					status: notification.status,
					sentStatus: notification.sentStatus,
				}))
				.sort(
					(a: NotificationsObjectType, b: NotificationsObjectType) =>
						b.createdAt.getTime() - a.createdAt.getTime(),
				);
			setNotifications(formattedNotifications);
		}
	}, [studentNotifications]);

	useEffect(() => {
		if (allNotifications?.getNotifications) {
			const notifications = allNotifications.getNotifications;
			const newNotification: NotificationsObjectType = {
				id: 'newNotification',
				title: notifications.title,
				message: notifications.message,
				createdAt: notifications.createdAt ? new Date(notifications.createdAt) : new Date(),
				readStatus: false,
				sentStatus: NotificationSentStatusEnum.SENT,
				status: NotificationStatusEnum.APPROVED,
				type: NotificationTypeEnum.GENERAL,
			};

			setNotifications((prevNotifications: NotificationsObjectType[]) => [
				newNotification,
				...prevNotifications,
			]);

			setStudentNotifications((prevNotifications: NotificationsObjectType[]) => [
				newNotification,
				...prevNotifications,
			]);
		}
	}, [allNotifications, setStudentNotifications]);
	const hasUnreadNotifications = notifications.some((notification) => !notification.readStatus);

	const notificationIconColor = 'black';
	const notificationIcon = hasUnreadNotifications ? 'notification-pending-' : 'notification-';
	const notificationIconUrl = toAbsoluteUrl(
		`/media/hgheader/${notificationIcon + notificationIconColor}.svg`,
	);

	const handleUserProfile = () => {
		if (disableClick) {
			return;
		}
		setOpenUserProfile((prevValue) => !prevValue);
	};

	const handleNotifications = async () => {
		setOpenNotifications((prevValue) => !prevValue);

		const updatedNotifications = notifications.map((notification) => ({
			...notification,
			readStatus: true,
		}));
		setNotifications(updatedNotifications);

		try {
			await markAllNotificationsAsRead();
		} catch (error) {
			console.error('Failed to mark notifications as read', error);
		}
	};

	return (
		<div className="user-container align_items_center">
			<button
				onClick={handleNotifications}
				className={`userNotButton ${!notification_icon ? 'd-none' : ''}`}>
				<img src={notificationIconUrl} alt="User notifications" height="35px" role="button" />
			</button>
			<div
				className={`align-items-center text-center ${!userProfileIcon ? 'd-none' : 'd-md-flex'}`}>
				<div className="col-md-4 col-12">
					<button onClick={handleUserProfile} className={`userProfileButton`}>
						<img
							src={imageUrl}
							alt="User Profile"
							height="100%"
							width="100%"
							className="img-fluid"
							style={{ objectFit: 'cover' }}
						/>
					</button>
				</div>
				<div className="col-md-12 col-12 text-md-start text-center">
					<div className="col-12 mt-md-0 g_mid user-name-text mt-2">
						{user.firstName + ' ' + user.lastName}
					</div>
					<div className="col-12 color17 user-email-text">{user.email}</div>
				</div>
			</div>

			{openUserProfile && <ProfilePopup handleClick={handleUserProfile} />}
			{openNotifications && (
				<NotificationPopup
					notifications={notifications}
					handleClose={() => setOpenNotifications((prevValue) => !prevValue)}
				/>
			)}
		</div>
	);
};

export default LoggedInUser;
